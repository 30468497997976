import React, { useContext, useEffect } from "react";
import { OptionsProvider } from "../../../contexts";
import { Modal, UpdateLocationForm } from "../../index";
import { useModalContext } from "../../../contexts/ModalProvider";
import { AuthContext } from "../../../contexts/AuthProvider";
import LocationModalContents from "./LocationModalContents";

export default function LocationContents() {
  const { setModal } = useModalContext();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const isNewUser = () => {
      if (!currentUser) return;
      else {
        if (currentUser.newUser) setModal(true);
      }
    };
    isNewUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return (
    <>
      <Modal>
        <LocationModalContents />
      </Modal>
      <div className="flex flex-col gap-5 w-small-laptop:flex-row">
        <OptionsProvider>
          <div className="w-full w-small-laptop:w-[700px] lg:w-[800px] h-auto bg-admin-dark rounded-md px-4">
            <UpdateLocationForm />
          </div>
        </OptionsProvider>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex items-center justify-center h-[750px] text-default-white bg-admin-dark rounded-md">
            Coming soon...
          </div>
          <div className="flex items-center justify-center h-[750px] text-default-white bg-admin-dark rounded-md">
            Coming soon...
          </div>
        </div>
      </div>
    </>
  );
}