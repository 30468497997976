import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

export default function Transfer() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.resturo.resturio";
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/us/app/resturio/id6739811265";
    } else {
      window.location.href = "/";
    }
  }, []);
  return null;
}