export type Mode = "CREATE" | "UPDATE" | "DELETE";

export type State = {
  type: "CREATE_LOCATION" | "UPDATE_LOCATION" | "DELETE_LOCATION";
  componentId: "CREATE" | "UPDATE" | "DELETE";
  mode: Mode;
};
export type Actions =
  | { type: "CREATE_LOCATION"; mode: Mode }
  | { type: "UPDATE_LOCATION"; mode: Mode }
  | { type: "DELETE_LOCATION"; mode: Mode };

const StateReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "CREATE_LOCATION":
      return {
        ...state,
        type: action.type,
        componentId: "CREATE",
        mode: "CREATE",
      };
    case "UPDATE_LOCATION":
      return {
        ...state,
        type: action.type,
        componentId: "UPDATE",
        mode: "UPDATE",
      };
    case "DELETE_LOCATION":
      return {
        ...state,
        type: action.type,
        componentId: "DELETE",
        mode: "DELETE",
      };
    default:
      return state;
  }
};

export default StateReducer;