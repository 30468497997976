import { UseFormSetValue } from "react-hook-form";
import { CreateLocationOptions } from "../../../../types";
import { Dispatch, SetStateAction } from "react";

type SelectionProps = {
  option: string;
  selectionMode: "single" | "multiple";
  fieldName: string;
  setValue?: UseFormSetValue<any>;
  selectedOptions: CreateLocationOptions;
  setSelectedOptions: Dispatch<SetStateAction<CreateLocationOptions>>;
  index?: number;
};

export const handleSelection = ({
  option,
  selectionMode,
  fieldName,
  selectedOptions,
  setSelectedOptions,
  index,
}: SelectionProps) => {
  if (selectionMode === "single") {
    switch (fieldName) {
      case `multiLocations.${index}.city`: {
        if (index === undefined) break;
        const cities = [...selectedOptions.cities];
        cities[index] = option;

        setSelectedOptions((prev) => ({
          ...prev,
          cities,
        }));
        break;
      }
      case "mainTag": {
        const mainTag = selectedOptions.mainTag === option ? "" : option;
        setSelectedOptions((prev) => ({
          ...prev,
          mainTag: mainTag,
        }));
        break;
      }
      case "additionalTag": {
        const additionalTag =
          selectedOptions.additionalTag === option ? "" : option;
        setSelectedOptions((prev) => ({
          ...prev,
          additionalTag: additionalTag,
        }));
        break;
      }
      default:
        break;
    }
  } else if (selectionMode === "multiple") {
    //placeholder
  }
};