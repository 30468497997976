import { useEffect, useState } from "react";
import { ExtendedUser, Location } from "../types";
import { useLocations } from "../contexts/LocationsProvider";
import _ from "lodash";

function useSourceData(
  currentUser?: ExtendedUser,
  locationData?: Location,
  selectedLocation?: Location,
) {
  const [sourceData, setSourceData] = useState<Location | undefined>(undefined);
  const { locations } = useLocations();

  useEffect(() => {
    const chooseData = () => {
      if (!currentUser) return;

      if (currentUser.type === "admin") return selectedLocation;

      if (currentUser.type === "location" && locationData) return locationData;
    };

    const data = chooseData();
    let location: Location | undefined;

    if (data && currentUser) {
      if (currentUser.type === "admin" && locations && locations.length !== 0) {
        location = locations.find(
          (location: Location) => location.docID === data.docID,
        );
        if (location) {
          const dataHasChanged =
            !_.isEqual(data.picture, location.picture) ||
            !_.isEqual(data.multiLocations, location.multiLocations) ||
            !_.isEqual(data.rewards, location.rewards);

          setSourceData(dataHasChanged ? location : data);
        } else setSourceData(data);
      } else if (currentUser.type === "location" && locationData) {
        location = locationData;
        if (location) {
          const dataHasChanged =
            !_.isEqual(data.picture, location.picture) ||
            !_.isEqual(data.multiLocations, location.multiLocations) ||
            !_.isEqual(data.rewards, location.rewards);

          setSourceData(dataHasChanged ? location : data);
        }
      }
    } else setSourceData(data);
  }, [currentUser, locationData, locations, selectedLocation]);

  return sourceData;
}

export default useSourceData;