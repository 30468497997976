import { CustomInputProps } from "../../../../../types";
import React from "react";

export default function CustomInput<T extends Record<string, any>>({
  placeholder,
  type,
  register,
  fieldName,
  options,
  inputMode,
  onKeyDown,
  className,
  error,
  label,
}: CustomInputProps<T>) {
  const inputProps: any = {
    ...register(fieldName, options),
    type,
    placeholder,
    className: `${className} w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500 ${
      type === "number" &&
      "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
    }`,
    id: fieldName,
    name: fieldName,
  };

  if (type === "number") {
    inputProps.inputMode = inputMode;
    inputProps.onKeyDown = onKeyDown;
  }

  return (
    <div className="mt-4">
      {label && (
        <label className="block text-admin-purple" htmlFor={fieldName}>
          {label}
        </label>
      )}
      <input {...inputProps} />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
}