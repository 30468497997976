import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import SharedInputs from "../global/Inputs/SharedInputs";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { onCreate, toastOptions } from "./actions";
import { setKey } from "react-geocode";
import { createFormDefaultValues } from "../../../../data";
import { ToastContainer } from "react-toastify";

export default function CreateLocationForm() {
  const methods = useForm<Location>({
    defaultValues: createFormDefaultValues,
  });
  const { reset, handleSubmit } = methods;
  const queryClient = useQueryClient();
  const { selectedOptions, setSelectedOptions } = useOptionsContext();

  const [loading, setLoading] = useState(false);

  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");

  const handleOnSubmit = async (data: Location) => {
    setLoading(true);
    try {
      await onCreate(data, selectedOptions, queryClient);
      toastOptions({
        status: "success",
        position: "top-left",
        message: "Location created successfully!",
      });
      reset(createFormDefaultValues);
      setSelectedOptions({
        cities: [],
        mainTag: "",
        additionalTag: "",
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });
      setLoading(false);
    } catch {
      toastOptions({
        status: "error",
        position: "top-left",
        message: "Failed to create location!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <h2 className="text-default-white text-xl pt-3">CREATE LOCATION</h2>
      <div className="mt-7 h-auto pb-7 w-full">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <SharedInputs formMethods={methods} />
          <button
            type="submit"
            className="w-full mt-4 py-2 bg-admin-purple cursor-pointer flex justify-center items-center"
            disabled={loading}
          >
            {loading ? <span className="admin-loader" /> : "Create Location"}
          </button>
        </form>
      </div>
    </>
  );
}