import { useEffect, useRef, useState } from "react";

interface Props {
  onItemClick: (item: string) => void;
  items: string[];
  liStyle: string;
  buttonWidth: number | undefined;
}

export default function DropdownMenu({
  onItemClick,
  items,
  liStyle,
  buttonWidth,
}: Props) {
  const listRef = useRef<HTMLUListElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    if (listRef.current) {
      const listHeight = listRef.current.offsetHeight;
      const itemHeight = (listRef.current.children[0] as HTMLElement)
        .offsetHeight;
      const maxHeight = itemHeight * 5;
      setHasScrollbar(listHeight > maxHeight);
    }
  }, [items]);

  const style = buttonWidth ? { width: `${buttonWidth}px` } : {};
  const className = `${hasScrollbar ? "max-h-40 overflow-y-auto" : ""}`;

  return (
    <ul ref={listRef} className={`${className} mt-2 absolute`} style={style}>
      {items.map((item: string, index: number) => {
        return (
          <li key={index} className={liStyle} onClick={() => onItemClick(item)}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}