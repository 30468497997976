import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface ModalInterface {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
}

type Props = {
  children: ReactNode;
};

const ModalContext = createContext<ModalInterface | undefined>(undefined);

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within an ModalProvider");
  }
  return context;
};
export default function ModalProvider({ children }: Props) {
  const [modal, setModal] = useState<boolean>(false);
  const value = {
    modal,
    setModal,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}