import { useQuery, useQueryClient } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebaseConfig";
import { Location } from "../../../types";

export const useHandleSignOut = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return async () => {
    try {
      await signOut(auth);
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      localStorage.removeItem("user");
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
};

const db = getFirestore();

export const fetchLocations = async () => {
  try {
    const locationsColRef = collection(db, "locations");
    const locationsColSnap = await getDocs(locationsColRef);
    return locationsColSnap.docs.map((doc) => {
      const data = doc.data();
      return {
        docID: doc.id,
        name: data.name,
        description: data.description,
        cities: data.cities,
        dateAdded: data.dateAdded,
        docRef: data.docRef,
        locationOTD: data.locationOTD,
        multiLocations: data.multiLocations,
        scannedQRs: data.scannedQRs,
        picture: data.picture,
        pictureHash: data.pictureHash,
        pointsRate: data.pointsRate,
        rewards: data.rewards,
        tags: data.tags,
      } as Location;
    });
  } catch (error) {
    console.error("Error fetching locations: ", error);
    throw error;
  }
};

export const useGetLocations = (shouldFetch: boolean) => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  });
};

export const fetchLocationByDocID = async (docID: string) => {
  const locationDocRef = doc(db, "locations", docID);
  const locationDocSnap = await getDoc(locationDocRef);

  if (locationDocSnap.exists()) {
    const data = locationDocSnap.data();
    return {
      docID: locationDocSnap.id,
      name: data.name,
      description: data.description,
      cities: data.cities,
      dateAdded: data.dateAdded,
      docRef: data.docRef,
      locationOTD: data.locationOTD,
      multiLocations: data.multiLocations,
      scannedQRs: data.scannedQRs,
      picture: data.picture,
      pictureHash: data.pictureHash,
      pointsRate: data.pointsRate,
      rewards: data.rewards,
      tags: data.tags,
    } as Location;
  } else {
    console.warn(`No location found with docID: ${docID}`);
    return;
  }
};

export const useGetLocationByDocID = (docID: string) => {
  return useQuery({
    queryKey: ["userLocation"],
    queryFn: () => fetchLocationByDocID(docID),
    enabled: !!docID,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
};

export const fetchCities = async () => {
  const citiesDoc = doc(db, "utils", `${process.env.REACT_APP_UTILS_KEY}`);
  try {
    const citiesDocSnap = await getDoc(citiesDoc);
    return citiesDocSnap.data()?.availableCities;
  } catch (error) {
    console.error("Error fetching locations: ", error);
    throw error;
  }
};

export const useFetchCities = () => {
  return useQuery({
    queryKey: ["cities"],
    queryFn: fetchCities,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
};