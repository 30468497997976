import { ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";

type ProtectedRouteProps = {
  children: ReactNode;
  allowedType: "location" | "admin";
};

export default function ProtectedRoute({
  children,
  allowedType,
}: ProtectedRouteProps) {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  if (!currentUser || currentUser.type !== allowedType) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}