import { useContext } from "react";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { useHandleSignOut } from "../actions";
import { LoadingSpinner } from "../../../index";
import { isMobileOnly } from "react-device-detect";

export default function AdminNavbar() {
  const handleSignOut = useHandleSignOut();
  const { currentUser } = useContext(AuthContext);

  return (
    <header className="absolute px-9 top-0 z-20 flex w-full justify-center bg-admin-dark">
      <div className="flex flex-row items-center justify-between w-full text-default-white text-md py-5">
        {!currentUser ? (
          <LoadingSpinner />
        ) : (
          <h1>
            Logged in as{" "}
            <span className="text-admin-purple">{currentUser.name}</span>
          </h1>
        )}
        <div className="flex flex-row justify-between gap-5 items-center">
          {!isMobileOnly && (
            <p className="text-admin-purple">
              Support:{" "}
              <a
                href="mailto:horia.angelescu@resturio.com"
                className="text-default-white"
              >
                horia.angelescu@resturio.com
              </a>
            </p>
          )}
          <button
            onClick={() => handleSignOut()}
            className="px-2 rounded-md h-fit bg-admin-purple shadow-[0px_0px_17px_-4px_#6c5ce7]"
          >
            <p className="py-2 px-4">Logout</p>
          </button>
        </div>
      </div>
    </header>
  );
}