import { useLocations } from "../../../../contexts/LocationsProvider";
import Dropdown from "../../../global/Dropdown";

export default function LocationSelector() {
  const { locations, setSelectedLocation } = useLocations();
  const buttonStyle =
    "py-2 px-5 rounded-md h-fit bg-admin-purple text-default-white flex items-center justify-between w-full";
  const liStyle =
    "p-2 h-fit bg-admin-purple text-default-white hover:bg-hover-admin-purple text-center cursor-pointer";

  const sortedLocations =
    locations?.slice().sort((a, b) => a.name.localeCompare(b.name)) || [];
  const locationNames = sortedLocations.map((location) => location.name);

  const handleSelect = (locationName: string) => {
    const selected = sortedLocations.find((loc) => loc.name === locationName);
    if (selected) {
      setSelectedLocation(selected);
    }
  };

  return (
    <Dropdown
      differ="location"
      items={locationNames}
      label={locationNames[0] || "Select a location"}
      buttonStyle={buttonStyle}
      liStyle={liStyle}
      onSelect={handleSelect}
    />
  );
}