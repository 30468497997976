import { createContext, ReactNode, useContext, useReducer } from "react";
import StateReducer, { Actions, State } from "../reducers/StateReducer";
import { AuthContext } from "./AuthProvider";
import { ExtendedUser } from "../types";

interface ActionsContextInterface {
  state: State;
  dispatch: (action: Actions) => void;
}

interface Props {
  children: ReactNode;
}

export const ActionsContext = createContext<
  ActionsContextInterface | undefined
>(undefined);

export const useActionsContext = () => {
  const context = useContext(ActionsContext);
  if (!context) {
    throw new Error("Component must be used within a ActionsManagerProvider");
  }
  return context;
};

export default function ActionsManagerProvider({ children }: Props) {
  const { currentUser } = useContext(AuthContext);

  const getInitialState = (currentUser: ExtendedUser | undefined): State => {
    if (currentUser?.type === "location") {
      return {
        type: "UPDATE_LOCATION",
        componentId: "UPDATE",
        mode: "UPDATE",
      };
    }
    return {
      type: "CREATE_LOCATION",
      componentId: "CREATE",
      mode: "CREATE",
    };
  };

  const INITIAL_STATE: State = getInitialState(currentUser);

  const [state, dispatch] = useReducer(StateReducer, INITIAL_STATE);

  return (
    <ActionsContext.Provider value={{ state, dispatch }}>
      {children}
    </ActionsContext.Provider>
  );
}