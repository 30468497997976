import { Helmet } from "react-helmet-async";
import {
  AdminContents,
  AdminNavbar,
  Layout,
  LocationContents,
} from "../../components";
import { ActionsManagerProvider, LocationsProvider } from "./../../contexts";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { ModalProvider } from "../../contexts";

export default function Dashboard() {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>{`Resturio ${currentUser?.type === "admin" ? "Admin" : "Location"} dashboard`}</title>
      </Helmet>
      <LocationsProvider>
        <ActionsManagerProvider>
          <AdminNavbar />
          <Layout className="my-32">
            {currentUser?.type === "admin" ? (
              <AdminContents />
            ) : (
              <ModalProvider>
                <LocationContents />
              </ModalProvider>
            )}
          </Layout>
        </ActionsManagerProvider>
      </LocationsProvider>
    </>
  );
}