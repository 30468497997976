import { NavbarProps } from "../../types";
import Logo from "./Logo";
import NavLinks from "./NavLinks";

export default function Navbar(props: NavbarProps) {
  return (
    <header
      ref={props.headerRef}
      className="absolute px-9 top-0 z-20 flex w-full justify-center mt-8 ipad:mt-10 ipad:px-20 w-small-laptop:px-44 w-desktop:px-56 w-desktop-lg:px-96"
      id="header"
    >
      <div className="flex flex-row items-center justify-between w-full">
        <Logo />
        <NavLinks />
      </div>
    </header>
  );
}
