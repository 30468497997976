import React, { Suspense } from "react";
import { LoadingSpinner } from "../index";
import { Iphone } from "./Iphone";
import { Canvas } from "@react-three/fiber";

export default function Model() {
  return (
    <Canvas camera={{ fov: 55, position: [5, -2, 16] }}>
      <ambientLight intensity={5} />
      <Suspense fallback={<LoadingSpinner />}>
        <Iphone position={[2.3, 0, 0]} />
      </Suspense>
    </Canvas>
  );
}