import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import { Credentials } from "../../types";
import { onSubmit } from "./actions";
import { Modal } from "../index";
import LoginModalContents from "./LoginModalContents";
import { useModalContext } from "../../contexts/ModalProvider";
import { toastOptions } from "./Dashboard/LocationForms/actions";
import { ToastContainer } from "react-toastify";

export default function Form() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>();

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { modal, setModal } = useModalContext();
  const handleOnSubmit = async (data: Credentials) => {
    setLoading(true);
    try {
      await onSubmit(data, dispatch, navigate);
      setLoading(false);
    } catch {
      toastOptions({
        status: "error",
        position: "top-left",
        message: "Wrong credentials! How did you find this page anyway?",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal>
        <LoginModalContents />
      </Modal>
      <div className="flex flex-col mt-5 w-full text-center">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <input
            {...register("email", { required: "Email is required." })}
            placeholder="Email"
            type="email"
            className="w-full bg-transparent placeholder:text-themed-orange text-themed-orange py-2 border-b-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500"
          />
          {errors.email?.message && (
            <span className="text-red-500">{String(errors.email.message)}</span>
          )}
          <input
            {...register("password", { required: "Password is required." })}
            placeholder="Password"
            type="password"
            className="w-full bg-transparent placeholder:text-themed-orange text-themed-orange py-2 border-b-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500 mt-5"
          />
          {errors.password?.message && (
            <span className="text-red-500">
              {String(errors.password.message)}
            </span>
          )}
          <button
            type="submit"
            className="w-full bg-themed-orange py-2 lg:cursor-pointer mt-5"
          >
            {loading ? <span className="admin-loader" /> : "Login"}
          </button>
        </form>
        <button
          className="mt-5 text-themed-orange cursor-pointer"
          onClick={() => setModal(!modal)}
        >
          <p className="float-right">Forgot password?</p>
        </button>
      </div>
    </>
  );
}