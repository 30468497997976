import { Credentials, ExtendedUser } from "../../types";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { Dispatch } from "react";
import { Actions } from "../../reducers/AuthReducer";
import { NavigateFunction } from "react-router-dom";
import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";

export const onSubmit = async (
  data: Credentials,
  dispatch: Dispatch<Actions>,
  navigate: NavigateFunction,
) => {
  const { email, password } = data;

  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredentials.user;
    const db = getFirestore();
    const userDocRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userType = userData.type || "user";
      const locationRestaurantRef: DocumentReference = userData.restaurant_id;
      let restaurantID: string | undefined = undefined;
      const name: string = userData.display_name || "";
      const isNewUser: boolean = userData.newUser;

      if (locationRestaurantRef) {
        const restaurantDocSnapshot = await getDoc(locationRestaurantRef);
        if (restaurantDocSnapshot.exists()) {
          restaurantID = locationRestaurantRef.id;
        }
      }

      const extendedUser: ExtendedUser = {
        ...user,
        type: userType,
        restaurantID,
        name,
        newUser: isNewUser,
      };

      dispatch({ type: "LOGIN", payload: extendedUser });
      if (userType === "admin") navigate("/admin/dashboard");
      else if (userType === "location") navigate("/location/dashboard");
    }
  } catch (error) {
    throw new Error("Wrong credentials! How did you find this page anyway?");
  }
};

export async function updateUserPassword(
  newPassword: string,
  currentPassword?: string,
) {
  const user = auth.currentUser;

  if (!user) throw new Error("User is not authenticated.");

  const db = getFirestore();
  const userDocRef = doc(db, "users", user.uid);

  const userSnapshot = await getDoc(userDocRef);
  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    const isNewUser: boolean = userData.newUser;

    if (isNewUser) await updateDoc(userDocRef, { newUser: false });
  }

  if (currentPassword) {
    const credential = EmailAuthProvider.credential(
      user.email || "",
      currentPassword,
    );
    await reauthenticateWithCredential(user, credential);
  }

  await updatePassword(user, newPassword);
}

export const sendPasswordReset = async (email: string) => {
  const auth = getAuth();

  if (!email) {
    throw new Error("Please enter a valid email address.");
  }
  await sendPasswordResetEmail(auth, email);
  return "Password reset email sent successfully.";
};