import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function Layout({ children, className }: Props) {
  return (
    <section
      className={`${className} px-9 w-screen h-auto relative ipad:px-20 w-desktop-md:px-40`}
    >
      {children}
    </section>
  );
}