import React from "react";
import { ActionDropdown, LocationManager } from "../../index";
import { OptionsProvider } from "../../../contexts";

export default function AdminContents() {
  return (
    <div className="flex flex-col gap-5 w-small-laptop:flex-row">
      <div className="w-full w-small-laptop:w-[700px] lg:w-[800px] h-auto">
        <ActionDropdown />
        <OptionsProvider>
          <LocationManager />
        </OptionsProvider>
      </div>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex items-center justify-center h-[750px] text-default-white bg-admin-dark rounded-md">
          Coming soon...
        </div>
        <div className="flex items-center justify-center h-[750px] text-default-white bg-admin-dark rounded-md">
          Coming soon...
        </div>
      </div>
    </div>
  );
}