import { useEffect, useState } from "react";
import DropdownMenu from "./DropdownMenu";
import { Actions } from "../../reducers/StateReducer";
import { actionLabels } from "../Admin/Dashboard/Dropdown/utils";

interface PropsBase {
  items: string[];
  label: string;
  differ: string;
  divStyle?: string;
  buttonStyle?: string;
  liStyle: string;
  onSelect?: (item: string) => void;
}

interface PropsWithDispatch extends PropsBase {
  dispatch: (action: Actions) => void;
  newStateList: Actions[];
}

interface PropsWithoutDispatch extends PropsBase {
  dispatch?: never;
  newStateList?: never;
}

type Props = PropsWithDispatch | PropsWithoutDispatch;

export default function Dropdown({
  items,
  label,
  dispatch,
  newStateList,
  divStyle,
  buttonStyle,
  liStyle,
  onSelect,
  differ,
}: Props) {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [text, setText] = useState<string>(label);
  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const buttonElement = document.getElementById(`dropdown-button-${differ}`);

    if (buttonElement) {
      setButtonWidth(buttonElement.getBoundingClientRect().width);
    }
  }, [buttonWidth, differ]);

  const handleStateSwitch = (newLabel: string) => {
    if (dispatch && newStateList) {
      const correspondingAction = newStateList.find(
        (action) => actionLabels[action.type] === newLabel,
      );
      if (correspondingAction) dispatch(correspondingAction);
    }
    setText(newLabel);
    setIsClicked(false);

    if (onSelect) onSelect(newLabel);
  };

  const handleClick = () => {
    setIsClicked((prevState) => !prevState);
  };

  return (
    <div className={divStyle}>
      <button
        className={buttonStyle}
        id={`dropdown-button-${differ}`}
        onClick={handleClick}
      >
        <p className="inline-block">{text}</p>
        <img
          src="/assets/DropdownArrow.png"
          alt="dropdown_arrow"
          className={`inline-block h-[6px] ml-5 transition duration-300 ${
            isClicked ? "rotate-180" : "rotate-0"
          }`}
        />
      </button>
      {isClicked && (
        <DropdownMenu
          onItemClick={handleStateSwitch}
          buttonWidth={buttonWidth}
          items={items}
          liStyle={liStyle}
        />
      )}
    </div>
  );
}