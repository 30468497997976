import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { ProtectedRoute, Transfer } from "./components";
import AuthProvider from "./contexts/AuthProvider";
import { Dashboard, Error, Home, Login } from "./pages";
import reportWebVitals from "./reportWebVitals";
import AccountDeletion from "./pages/AccountDeletion";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/scan",
        element: <Transfer />,
      },
      {
        path: "admin/dashboard",
        element: (
          <ProtectedRoute allowedType="admin">
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "location/dashboard",
        element: (
          <ProtectedRoute allowedType="location">
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/additional_info",
        element: <AccountDeletion />,
      },
    ],
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();