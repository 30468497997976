import { useModalContext } from "../../contexts/ModalProvider";
import React, { useState } from "react";
import { sendPasswordReset } from "./actions";

export default function LoginModalContents() {
  const { modal, setModal } = useModalContext();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSendEmail = async () => {
    try {
      await sendPasswordReset(email);
      setLoading(!loading);
      setModal(!modal);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 ipad:w-3/4 w-small-laptop:w-1/2 w-desktop:w-[500px] h-auto flex flex-col items-center justify-center p-4 bg-modal rounded shadow-md">
      <img
        src="/assets/delete-icon.png"
        alt="Delete Icon"
        className="w-6 h-auto absolute top-2 right-3 z-10 cursor-pointer"
        onClick={() => setModal(!modal)}
      />
      <h3 className="text-lg font-semibold mb-4 text-admin-purple">
        Your email:
      </h3>
      <input
        type="email"
        name="forgot-email"
        id="forgot-email"
        value={email}
        autoComplete={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
      />
      <button
        type="submit"
        className="w-full py-2 bg-admin-purple text-default-white rounded hover:bg-hover-admin-purple mt-4"
        onClick={handleSendEmail}
      >
        {loading ? <span className="admin-loader" /> : "Submit"}
      </button>
    </div>
  );
}