import { useActionsContext } from "../../../contexts/ActionsManagerProvider";
import CreateLocation from "./LocationForms/CreateLocationForm";
import UpdateLocation from "./LocationForms/UpdateLocationForm";
import DeleteLocation from "./LocationForms/DeleteLocation";
import { useEffect } from "react";
import { useOptionsContext } from "../../../contexts/OptionsProvider";

const componentMap = {
  CREATE: <CreateLocation />,
  UPDATE: <UpdateLocation />,
  DELETE: <DeleteLocation />,
};

export default function LocationManager() {
  const { selectedOptions, setSelectedOptions } = useOptionsContext();
  const { state } = useActionsContext();
  const { mode } = state;

  useEffect(() => {
    const updateSelectedOptions = () => {
      switch (mode) {
        case "CREATE":
          return {
            cities: [],
            mainTag: "",
            additionalTag: "",
            files: {
              main_menu: null,
              banners: [],
              rewards: [],
            },
          };
        case "UPDATE":
          const { mainTag, additionalTag, cities } = selectedOptions;
          return {
            cities,
            mainTag,
            additionalTag,
            files: {
              main_menu: null,
              banners: [],
              rewards: [],
            },
          };
        default:
          return;
      }
    };

    const contextData = updateSelectedOptions();
    if (contextData) setSelectedOptions(contextData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  return (
    <div className="w-full h-auto bg-admin-dark rounded-md px-4 mt-5">
      {componentMap[state.componentId]}
    </div>
  );
}