import React, { ReactNode } from "react";
import { useModalContext } from "../../contexts/ModalProvider";

interface Props {
  children: ReactNode;
}

export default function Modal({ children }: Props) {
  const { modal } = useModalContext();

  if (!modal) return null;

  return (
    <div className="modal z-50">
      <div className="modal-overlay"></div>
      {children}
    </div>
  );
}