import React, { Suspense, SyntheticEvent, useEffect, useState } from "react";
import { Location } from "../../../../../types";
import { useOptionsContext } from "../../../../../contexts/OptionsProvider";
import {
  FormState,
  Path,
  UseFormRegister,
  ValidationRule,
} from "react-hook-form";
import { handleDeleteFile, handleFileChange } from "./actions";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingSpinner } from "../../../../index";

type FieldName = Path<Location>;

interface Props {
  register: UseFormRegister<Location>;
  formState: FormState<Location>;
  fieldName: FieldName;
  label: string;
  For: "main_menu" | "banners" | "reward";
  options?: {
    required?: string | ValidationRule<boolean>;
    onChange?: (e: SyntheticEvent) => void;
  };
  index?: number;
  sourceData?: Location;
}

export default function FileInput({
  register,
  fieldName,
  options,
  label,
  For,
  formState: { errors },
  index,
  sourceData,
}: Props) {
  const { setSelectedOptions, setRemovedFiles, selectedOptions } =
    useOptionsContext();
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const { state } = useActionsContext();
  const { mode } = state;

  useEffect(() => {
    if (mode === "UPDATE" && sourceData) {
      switch (For) {
        case "main_menu":
          setPreviewUrls([sourceData.picture]);
          break;
        case "reward":
          if (index !== undefined && sourceData.rewards[index] !== undefined)
            setPreviewUrls([sourceData.rewards[index].rewardPicture]);
          break;
        case "banners":
          if (
            index !== undefined &&
            sourceData.multiLocations[index] !== undefined
          )
            setPreviewUrls(sourceData.multiLocations[index].pictures);

          break;
        default:
          break;
      }
    }
  }, [For, index, mode, sourceData]);

  return (
    <div className="mt-4 h-auto">
      <label
        htmlFor={fieldName}
        className="p-2 rounded-md bg-admin-purple text-default-white cursor-pointer block w-fit"
      >
        {label}
      </label>
      <input
        {...register(fieldName, {
          ...options,
          onChange: (e: SyntheticEvent) =>
            handleFileChange({
              e,
              setPreviewUrls,
              For,
              setSelectedOptions,
              mode,
              sourceData,
              setRemovedFiles,
              index,
            }),
        })}
        id={fieldName}
        type="file"
        multiple={For === "banners"}
        className="hidden"
      />
      {(previewUrls.length === 0 ||
        (mode === "UPDATE" && previewUrls[0] === "")) && (
        <ErrorMessage
          errors={errors}
          name={fieldName}
          render={({ message }) => <p className="text-red-500">{message}</p>}
        />
      )}
      <Suspense fallback={<LoadingSpinner />}>
        <div className="grid grid-cols-2 gap-2">
          {previewUrls[0] !== "" &&
            previewUrls.map((url, idx) => (
              <div
                key={idx}
                className={`relative flex justify-center items-center w-[125px] overflow-hidden mt-3 ipad:w-[250px] w-small-laptop:h-tablet:w-[140px] w-desktop:w-[175px] ${For === "reward" ? "h-[90px] ipad:h-[161px] w-small-laptop:h-tablet:h-[99px] w-desktop:h-[118px]" : "h-[70px] ipad:h-[141px]  w-small-laptop:h-tablet:h-[79px]  w-desktop:h-[98px]"}`}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteFile({
                      previewUrls,
                      idx,
                      setPreviewUrls,
                      selectedOptions,
                      setSelectedOptions,
                      For,
                      mode,
                      sourceData,
                      setRemovedFiles,
                      index,
                    });
                  }}
                >
                  <img
                    src="/assets/delete-icon.png"
                    alt="Delete Icon"
                    className="w-6 h-auto absolute top-0.5 left-0.5 z-10"
                  />
                </button>
                <img
                  src={url}
                  alt="Selected File"
                  className="object-cover object-center"
                />
              </div>
            ))}
        </div>
      </Suspense>
    </div>
  );
}