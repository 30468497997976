import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Location } from "../types";
import { useAdminLocations } from "../hooks/useAdminLocations";

interface LocationsContextInterface {
  locations: Location[] | undefined;
  isLoading: boolean;
  error: any;
  selectedLocation: Location | undefined;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
}

type Props = {
  children: ReactNode;
};

const LocationsContext = createContext<LocationsContextInterface | undefined>(
  undefined,
);

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};

export default function LocationsProvider({ children }: Props) {
  const { data: locations, isLoading, error } = useAdminLocations();
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);

  const sortedLocations = locations
    ? [...locations].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  if (sortedLocations.length && selectedLocation === undefined)
    setSelectedLocation(sortedLocations[0]);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        isLoading,
        error,
        selectedLocation,
        setSelectedLocation,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}