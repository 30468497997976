import React, { useEffect, useState } from "react";
import { Path, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import FileInput from "./FileInput";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import { getFileRef } from "../../../../../firebase/actions";
import { useOptionsContext } from "../../../../../contexts/OptionsProvider";

interface Props {
  formMethods: UseFormReturn<Location>;
  sourceData?: Location;
}

type FormPath = Path<Location>;

export default function RewardInputs({ formMethods, sourceData }: Props) {
  const { register, control, formState } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rewards",
  });
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;
  const { setRemovedFiles } = useOptionsContext();
  const [isChecked, setIsChecked] = useState<boolean[]>([]);

  useEffect(() => {
    if (!sourceData) return;
    const initialCheckedState = sourceData.rewards.map(
      (reward) => reward.rewardWarning !== "",
    );
    setIsChecked(initialCheckedState);
  }, [sourceData]);

  const handleNumberInput = (e: KeyboardEvent): void => {
    const isNumber = /[0-9]/.test(e.key);
    const isAllowedKey = ["Backspace", "Control", "Meta"].includes(e.key);
    const isCopyPaste = (e.ctrlKey || e.metaKey) && ["v", "c"].includes(e.key);

    if (!isNumber && !isAllowedKey && !isCopyPaste) {
      e.preventDefault();
    }
  };

  const handleCheckboxChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCheckedState = [...isChecked];
      updatedCheckedState[index] = e.target.checked;
      setIsChecked(updatedCheckedState);
    };

  const handleDeleteFiles = (index: number, sourceData?: Location) => {
    if (mode === "UPDATE" && sourceData) {
      const firebaseUrl: string | undefined =
        sourceData.rewards[index].rewardPicture;

      if (firebaseUrl) {
        const fileRef = getFileRef(sourceData, firebaseUrl, "reward");
        const urlArray = fileRef.fullPath.split("/");
        const fileName = urlArray[urlArray.length - 1];
        setRemovedFiles((prev) => [
          ...prev,
          {
            removedFile: {
              deleteFrom: fileRef,
              name: fileName,
              from: "reward",
            },
          },
        ]);
      }
    }
  };

  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4 pb-7"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Reward {index + 1}</h3>
            <label
              className="mt-4 block text-admin-purple"
              htmlFor="rewardName"
            >
              Name
            </label>
            <input
              {...register(`rewards.${index}.rewardTitle` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Anything you fancy offering."
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              id="rewardName"
              name="rewardName"
            />
            {errors.rewards?.[index]?.rewardTitle?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardTitle?.message)}
              </span>
            )}
            <label
              className="mt-4 block text-admin-purple"
              htmlFor="rewardPrice"
            >
              Price
            </label>
            <input
              {...register(`rewards.${index}.rewardCost` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
                valueAsNumber: true,
              })}
              type="number"
              inputMode="numeric"
              placeholder="Price of the reward"
              onKeyDown={() => handleNumberInput}
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              id="rewardPrice"
              name="rewardPrice"
            />
            {errors.rewards?.[index]?.rewardCost?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardCost?.message)}
              </span>
            )}
            <label
              className="mt-4 block text-admin-purple"
              htmlFor="rewardDescription"
            >
              Description
            </label>
            <textarea
              {...register(`rewards.${index}.rewardDescription` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              placeholder="Short description"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              id="rewardDescription"
              name="rewardDescription"
            />
            {errors.rewards?.[index]?.rewardDescription?.message && (
              <span className="text-red-500 text-sm mt-1">
                {String(errors.rewards?.[index]?.rewardDescription?.message)}
              </span>
            )}
            <div>
              <div className="mt-4 flex flex-row justify-between  items-center">
                <label
                  className="block text-admin-purple"
                  htmlFor="additional-info"
                >
                  Any special rules? (e.g. 18+ / only certain days)
                </label>
                <input
                  type="checkbox"
                  name="additional-info"
                  id="additional-info"
                  className="ml-2 w-6 h-6 text-admin-purple bg-default-white border-0 rounded-md checked:bg-admin-purple focus:ring-0 focus:outline-none focus:border-0"
                  checked={isChecked[index] || false}
                  onChange={handleCheckboxChange(index)}
                />
              </div>

              {isChecked[index] && (
                <>
                  <input
                    {...register(`rewards.${index}.rewardWarning` as FormPath)}
                    type="text"
                    placeholder="e.g. 18+ / only certain days"
                    className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
                  />
                  {errors.rewards?.[index]?.rewardWarning?.message && (
                    <span className="text-red-500 text-sm mt-1">
                      {String(errors.rewards?.[index]?.rewardWarning?.message)}
                    </span>
                  )}
                </>
              )}
            </div>
            <FileInput
              register={register}
              fieldName={`rewards.${index}.rewardPicture` as FormPath}
              options={{
                required: mode === "CREATE" ? "This field is required!" : false,
              }}
              label={`${
                mode === "CREATE"
                  ? "Upload a reward image"
                  : "Change the reward image"
              }`}
              For="reward"
              formState={formState}
              index={index}
              sourceData={sourceData}
            />

            {index > 0 && (
              <button
                onClick={() => {
                  remove(index);
                  handleDeleteFiles(index, sourceData);
                }}
                className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            rewardTitle: "",
            rewardCost: null,
            rewardDescription: "",
            rewardPicture: "",
            rewardWarning: "",
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add more rewards</p>
      </button>
    </div>
  );
}