import * as THREE from "three";
import { useRef, useState } from "react";
import { Decal, useGLTF } from "@react-three/drei";
import { GLTFResult } from "../../types";

export function Iphone(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(
    "/assets/iphone-transformed.glb",
  ) as GLTFResult;

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = "/assets/resturio-demo.webm";
    vid.crossOrigin = "anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.play();
    return vid;
  });

  const videoTexture = new THREE.VideoTexture(video);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="back_plate"
          geometry={nodes.back_plate.geometry}
          material={materials.PaletteMaterial001}
        />
        <mesh
          name="bottom_rings"
          geometry={nodes.bottom_rings.geometry}
          material={materials.PaletteMaterial002}
        />
        <mesh
          name="sides"
          geometry={nodes.sides.geometry}
          material={materials.PaletteMaterial003}
        />
        <mesh
          name="speaker_rings"
          geometry={nodes.speaker_rings.geometry}
          material={materials.PaletteMaterial004}
        />
        <mesh
          name="lines"
          geometry={nodes.lines.geometry}
          material={materials.PaletteMaterial005}
        />
        <mesh
          name="speakers"
          geometry={nodes.speakers.geometry}
          material={materials.PaletteMaterial006}
        />
        <mesh
          name="camera_island"
          geometry={nodes.camera_island.geometry}
          material={materials.PaletteMaterial007}
        />
        <mesh
          name="lenses"
          geometry={nodes.lenses.geometry}
          material={materials.PaletteMaterial008}
        />
        <mesh
          name="flash"
          geometry={nodes.flash.geometry}
          material={materials.PaletteMaterial009}
        />
        <mesh
          name="flash_int"
          geometry={nodes.flash_int.geometry}
          material={materials.PaletteMaterial010}
        />
        <mesh
          name="flash_light"
          geometry={nodes.flash_light.geometry}
          material={materials.PaletteMaterial011}
        />
        <mesh
          name="lenses_mid_ext"
          geometry={nodes.lenses_mid_ext.geometry}
          material={materials.PaletteMaterial012}
        />
        <mesh
          name="left_side"
          geometry={nodes.left_side.geometry}
          material={materials.PaletteMaterial013}
        />
        <mesh
          name="front_camera_ext"
          geometry={nodes.front_camera_ext.geometry}
          material={materials.PaletteMaterial014}
        />
        <mesh
          name="screen"
          geometry={nodes.screen.geometry}
          material={materials.PaletteMaterial015}
        >
          <meshBasicMaterial transparent opacity={0} />
          <Decal scale={[7, 14.2, 1]}>
            <meshBasicMaterial map={videoTexture} />
          </Decal>
        </mesh>
      </group>
    </group>
  );
}